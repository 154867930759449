<template>
  <div class="box">
    <div class="title">隐私政策</div>
    <div class="content">

      交警智慧运维平台尊重并保护所有使用服务用户的个人隐私权，高度重视用户的隐私及个人信息的保护，本隐私政策帮助您了解我们收集哪些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。

我们将依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》以及其他相关法律法规、业界成熟的安全标准收集和本协议的约定使用您的个人信息，除本隐私权政策另有规定外，在未征得您事先许可的情况下，交警智慧运维不会将这些信息对外披露或向第三方提供。

1、收集和使用个人信息的范围。

（1）注册账号：您注册并登录交警智慧运维账号时至少向我们提供账号名称、手机号码。注册成功后，您提供的上述信息，将在您使用智慧运维平台和服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息或对您的个人信息进行匿名化处理，法律法规另有规定的除外。

（2）同时使用手机号码注册成功后，我们可能收集如下信息：

 (2.1）设备信息：以便我们能在设备上为您提供服务，我们可能会将您的设备信息或电话号码与账号相关联，并收集设备属性信息、设备状态信息、设备链接信息。

（2.2）展示和推送内容：通过使用收集的信息，我们会向您提供搜索结果、用户研究分析与统计等服务。

（2.3）搜索功能：当您使用交警智慧运维提供的搜索功能时，我们会收集您查询的关键字信息以及您在使用交警智慧运维服务时所阅览或要求的其他信息和内容详情。该等关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本指引的限制范围内。只有当您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本指引对其进行处理与保护。

（3）向您提供交警智慧运维产品或服务的附加业务功能

（3.1）定位功能：GPS地址和位置信息，当您开启设备定位功能并使用交警智慧运维基于位置提供的相关服务时，我们会收集有关您的位置信息。

（3.2）摄像头权限：主要用户拍照工作状态上传。

（3.3）相册权限：用户可使用app拍照上传。

（4）华为SDK用途：获取安装列表用于检测华为移动服务是否安装
	
（4.1）接入华为推送；

（4.2）使用 huawei.hms 执行二维码扫描功能；


（5）其他用途：当我们要将信息用于本隐私政策未载明的其他用途，将会事先征求您的同意。按照法律法规要求，以下情况我们将无法满足您的上述请求：

（5.1）与国家安全、国家利益相关的；

（5.2）与公共安全、公共利益相关的；

（5.3）与您或者第三方重大利益相关并可能会导致严重损害的；

（5.4）与犯罪、司法程序、政府程序等直接相关的；

（5.5）您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；

（5.6）涉及商业秘密的;

2、我们如何保护您的个人信息

为防止您的信息丢失、未经您同意授权的访问、公开披露、泄露、转让等导致您个人信息的受损，交警智慧运维平台将采取一切合理、可靠、可行的方案与措施，保障您的个人信息安全。

（1）安全措施：

（1.1）我们将以国家安全标准和法律法规的规定收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。同时，我们会对个人信息进行加密技术，以确保数据的保密性与安全性。


（1.2）对员工信息接触者签署保密协议，定期进行安全能力与意识的培训。专岗专职，只有授权员工方可访问个人信息，若违反保密协议，将追究员工相关法律责任。


（1.3）严格选择合作伙伴，对其进行背景调查，与有知名度且信誉良好的企业合作；与合作伙伴信息接触者签署保密协议，约定泄密的违约责任，并仅提供必要合理的信息。


（1.4）成立安全团队，安全团队负责研发和应用安全技术和程序，以确保交警智慧运维平台及个人信息的安全。我们将对安全团队负责人和信息技术负责人进行背景调查，签署保密协议，约定泄密的法律责任，并持续对其进行安全能力与意识的培训。


（1.5）我们建立完善的信息安全管理制度和内部安全事件处置机制等。


（2）保存期限：您在使用交警智慧运维产品及服务期间，我们将持续为您保存您的个人信息。如果您将个人信息修改，我们会保存修改后的信息。

 （3）安全事件通知

 （3.1）我们会预先制定互联网安全事件预警方案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。

（3.2）若发生个人信息安全事件，我们将通过您预留的个人信息（包含手机号码、电子邮箱等）及时通知您，并告知您案件进展和影响，我们将联合安全团队、法务部、技术部共同采取积极有效的处理手段，采取补救措施以降低风险。同时，我们将根据有关政府部门及法律法规要求，主动上报安全事件的情况，并可能采取法律手段解决安全事件。

（4）如果决定更改隐私政策，我们会在本政策中及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。

  3、法律责任与免责：

（1）浙江舍为思智慧科技开发有限公司对平台进行停机维护、定期检查、更新软硬件、针对突发事件、不可抗力、电脑病毒、系统故障等因素导致的正常服务中断、中止，浙江舍为思智慧科技开发有限公司不承担责任，平台将尽力避免服务中断并将中断时间限制在最短时间内。

（2）用户因第三方如电信部门的通讯线路故障、技术问题、网络、电脑故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，浙江舍为思智慧科技开发有限公司不承担责任。

（3）因用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方向交警智慧运维主张的索赔，由用户自行承担。

（4）用户在交警智慧运维平台发表的观点及立场，并不代表浙江舍为思智慧科技开发有限公司的立场，用户应自行对发表内容负责。


（5）请客户自行妥善保管个人资料，将个人信息提供足够保障及备份，并采取适当的预防措施降低电脑病毒或其他恶意破坏性举动的风险。


（6）由于您的隐私权对本网站相当重要，除非有权机关依法调取或者第三条约定，交警智慧运维平台不会无故把您的个人信息提供给无关的第三方，但不对第三人恶意侵权导致信息泄露而产生的后果负责。

4、知识产权：

（1）本软件是由浙江舍为思智慧科技开发有限公司开发。交警智慧运维的一切知识产权，以及与软件相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图像、图表、色彩、界面设计、版面框架、有关数据、附加程序、印刷材料或电子文档等均归浙江舍为思智慧科技开发有限公司所有，受著作权法和国际著作权条约以及其他知识产权法律法规的保护。

（2）未经浙江舍为思智慧科技开发有限公司书面同意，用户不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何三方实施、利用、转让上述知识产权。出现上述未经许可之行为时，浙江舍为思智慧科技开发有限公司保留追究相关责任人法律责任之权利。

5、修改与解释权：

根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，浙江舍为思智慧科技开发有限公司有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，您可在浙江舍为思智慧科技开发有限公司官方网站查阅最新版协议条款，该公布行为视为浙江舍为思智慧科技开发有限公司已经通知用户修改内容，而不另行对用户进行个别通知。在浙江舍为思智慧科技开发有限公司改协议条款后，如果您不接受修改后的条款，请立即停止使用浙江舍为思智慧科技开发有限公司提供的服务，您继续使用浙江舍为思智慧科技开发有限公司提供的服务将被视为已接受了修改后的协议。

6、法律适用、管辖与其他：

（1）浙江舍为思智慧科技开发有限公司郑重提醒用户注意本协议中可能存在的免除交警智慧运维责任的条款，请用户仔细阅读，自主考虑风险。

（2）本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律；如法律无相关规定的，参照商业惯例或行业惯例。

（3）与本协议、使用交警智慧运维发生的任何争议或纠纷，双方应尽量友好协商解决；

（4）本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。 


	
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>
.box {
  padding: 0 20px 0;
  text-align: center;
  background: #fff;
  min-height: 100vh;
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 0;
  }
  .content {
    font-size: 12px;
    line-height: 20px;
  }
}
</style>